import React, { useEffect, useState } from 'react';
import { Field, FieldProps, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, DatePicker, Input } from '@gymondo/frontend-core/components';
import text from '../../lang';
import { FormSubmissionError } from '..';
import { SignUpArgs, Voucher } from 'state/api.types';
import { Partner } from 'state/config';
import { Tracker } from '@gymondo/frontend-core/utils';
import Typography from '@gymondo/frontend-core/typography';
import style from './SignUpForm.module.scss';

interface SignUpFormProps {
    onSubmit: (data: SignUpArgs) => void;
    onCheckVoucher: (voucher_code: string) => void;
    onClickResetPassword: () => void;
    showResetPassword: boolean;
    initialVoucher: string;
    checkedVoucher: Voucher | null;
    partner: Partner;
}

interface SignUpInitialValues {
    email: string;
    password: string;
    birthDate?: number;
    newsletter: boolean;
    insurance_number?: string;
    voucher_code: string;
}

const getSignUpFormInitialValues = (
    hasInsurance: boolean,
    hasBirthDate: boolean,
    voucherCode: string,
): SignUpInitialValues => {
    const initialValues: SignUpInitialValues = {
        email: '',
        password: '',
        newsletter: false,
        voucher_code: voucherCode,
    };

    if (hasInsurance) {
        initialValues.insurance_number = '';
    }

    if (hasBirthDate) {
        initialValues.birthDate = 0;
    }

    return initialValues;
};

const SignUpForm: React.FC<SignUpFormProps> = ({
    onSubmit,
    onCheckVoucher,
    onClickResetPassword,
    showResetPassword,
    initialVoucher,
    checkedVoucher,
    partner,
}) => {
    const [formSubmissionError, setFormSubmissionError] = useState('');

    const requireCompanyEmail = partner.REQUIRE_COMPANY_EMAIL;
    const emailRegexPattern = partner.EMAIL_REGEX as string;

    const insuranceIsRequired =
        partner.HAS_INSURANCE &&
        (!partner.REQUIRE_INSURANCE_OR_VOUCHER || (partner.REQUIRE_INSURANCE_OR_VOUCHER && !checkedVoucher));

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required(text('validation_email_note'))
            .email(text('validation_email_invalid'))
            .concat(
                emailRegexPattern !== ''
                    ? Yup.string().matches(new RegExp(emailRegexPattern), text('validation_email_dak_invalid'))
                    : Yup.string().notRequired(),
            ),
        password: Yup.string().required(text('validation_password_note')).min(8, text('validation_password_invalid')),
        birthDate: partner.REQUIRE_BIRTHDATE_ON_SIGNUP
            ? Yup.string().required('bitte auswählen')
            : Yup.string().notRequired(),
        insurance_number: Yup.string().concat(
            insuranceIsRequired
                ? Yup.string()
                      .required(text('validation_insurance_number_note'))
                      .matches(/^[A-Z][0-9]{9}$/, text('validation_insurance_number_invalid'))
                : Yup.string().notRequired(),
        ),
    });

    return (
        <Formik
            initialValues={getSignUpFormInitialValues(
                partner.HAS_INSURANCE,
                partner.REQUIRE_BIRTHDATE_ON_SIGNUP,
                initialVoucher,
            )}
            validationSchema={validationSchema}
            onSubmit={async (data, { setSubmitting }) => {
                try {
                    await onSubmit(data);
                    Tracker.push({
                        event: Tracker.EVENTS.TRACKING,
                        eventCategory: 'prevention',
                        eventAction: 'sign_up',
                        eventLabel: `email`,
                    });
                } catch (error: unknown) {
                    setSubmitting(false);
                    const _message = (error as Record<string, unknown>).message as string;
                    setFormSubmissionError(_message);
                }
            }}
        >
            {({ dirty, isSubmitting, isValid, values, setFieldValue, setFieldTouched }) => (
                <Form>
                    {formSubmissionError && <FormSubmissionError message={formSubmissionError} className="p" />}
                    <div className="row">
                        <div className="col-xs-12 col-md">
                            <Field name="email">
                                {({ field, form: { errors, touched } }: FieldProps) => (
                                    <Input
                                        field={{
                                            ...field,
                                            placeholder: requireCompanyEmail
                                                ? text('signup_placeholder_email_partner')
                                                : text('signup_placeholder_email'),
                                            type: 'email',
                                        }}
                                        form={{
                                            errors: errors,
                                            touched: touched,
                                        }}
                                        label={
                                            requireCompanyEmail
                                                ? text('signup_label_email_partner')
                                                : text('signup_label_email')
                                        }
                                        multiline={false}
                                    />
                                )}
                            </Field>
                        </div>
                        <div className="col-xs-12 col-md">
                            <Field name="password">
                                {({ field, form: { errors, touched } }: FieldProps) => (
                                    <Input
                                        field={{
                                            ...field,
                                            placeholder: text('signup_placeholder_password'),
                                            type: 'password',
                                        }}
                                        form={{
                                            errors: errors,
                                            touched: touched,
                                        }}
                                        label={text('signup_label_password')}
                                        multiline={false}
                                    />
                                )}
                            </Field>
                            {showResetPassword && (
                                <span className="reset-password-link" onClick={onClickResetPassword}>
                                    {text('reset_password_text')}
                                </span>
                            )}
                        </div>
                    </div>
                    {partner.REQUIRE_BIRTHDATE_ON_SIGNUP && (
                        <div className="row">
                            <div className="col-xs-12">
                                <Field name="birthDate">
                                    {({
                                        field,
                                        form: { errors, touched, setFieldTouched, setFieldValue },
                                    }: FieldProps) => (
                                        <DatePicker
                                            field={{
                                                ...field,
                                            }}
                                            form={{
                                                setFieldTouched: setFieldTouched,
                                                setFieldValue: setFieldValue,
                                                errors: errors,
                                                touched: touched,
                                            }}
                                            i18nValues={{
                                                day: 'Tag',
                                                month: 'Monat',
                                                year: 'Jahr',
                                            }}
                                            label="Geburtstag"
                                            variant="primary"
                                        />
                                    )}
                                </Field>
                            </div>
                        </div>
                    )}
                    {partner.HAS_INSURANCE && (
                        <div className="row">
                            <div className="col-xs-12 col-md-6">
                                <Field name="insurance_number">
                                    {({ field, form: { errors, touched } }: FieldProps) => (
                                        <Input
                                            field={{
                                                ...field,
                                                placeholder: text('signup_placeholder_insurance_number'),
                                                type: 'text',
                                            }}
                                            form={{
                                                errors: errors,
                                                touched: touched,
                                            }}
                                            label={text('signup_label_insurance_number')}
                                            multiline={false}
                                        />
                                    )}
                                </Field>
                            </div>
                        </div>
                    )}
                    {partner.HAS_VOUCHER && (
                        <div className="row middle-xs voucher">
                            <div className="col-xs">
                                <div className="box">
                                    {checkedVoucher && <div>Super, dein Gutscheincode wurde aktiviert!</div>}
                                    {!checkedVoucher && (
                                        <Field name="voucher_code">
                                            {({ field, form: { errors, touched } }: FieldProps) => (
                                                <Input
                                                    field={{
                                                        ...field,
                                                        placeholder: text('signup_placeholder_voucher'),
                                                        type: 'text',
                                                    }}
                                                    form={{
                                                        errors: errors,
                                                        touched: touched,
                                                    }}
                                                    label={text('signup_label_voucher')}
                                                    multiline={false}
                                                />
                                            )}
                                        </Field>
                                    )}
                                </div>
                            </div>
                            <div className="col-xs padding">
                                <div className="box">
                                    <Button
                                        className="btn-gym--primary"
                                        htmlType="button"
                                        disabled={!checkedVoucher && !values.voucher_code}
                                        onClick={async () => {
                                            try {
                                                const voucher_code = checkedVoucher ? '' : values.voucher_code;
                                                if (checkedVoucher) {
                                                    setFieldValue('voucher_code', '');
                                                }
                                                await onCheckVoucher(voucher_code);

                                                setFieldTouched('insurance_number', true);
                                            } catch (error: unknown) {
                                                const _message = (error as Record<string, unknown>).message as string;
                                                setFormSubmissionError(_message);
                                            }
                                        }}
                                    >
                                        {!checkedVoucher && text('signup_activate_voucher_button')}
                                        {checkedVoucher && text('signup_delete_voucher_button')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="row">
                        <div className="col-xs-12 col-md">
                            {partner.HAS_REGULAR_DISCLAIMER && (
                                <div className="disclaimer">
                                    Zu Abrechnungszwecken wird Gymondo meine Versichertennummer einmalig zur Überprüfung
                                    an meine Krankenversicherung übermitteln. Zur Bestätigung des Leistungsanspruchs
                                    wird die Krankenversicherung den Status meiner Versicherung an Gymondo zurückmelden.
                                </div>
                            )}
                            {partner.CUSTOM_DISCLAIMER_HTML && (
                                <div
                                    className="disclaimer"
                                    dangerouslySetInnerHTML={{ __html: partner.CUSTOM_DISCLAIMER_HTML }}
                                ></div>
                            )}
                            {!partner.HIDE_DEFAULT_DISCLAIMER && (
                                <div className="disclaimer">{text('signup_confirm_terms')}</div>
                            )}
                        </div>
                    </div>
                    <Button htmlType="submit" disabled={!dirty || isSubmitting || !isValid}>
                        {text('signup_button')}
                    </Button>
                    {partner.SIGNUP_CTA_TEXT && (
                        <Typography variant="body1" className={style.ctaBottom}>
                            {partner.SIGNUP_CTA_TEXT}
                        </Typography>
                    )}
                </Form>
            )}
        </Formik>
    );
};

export default SignUpForm;
